import React, { Suspense, useEffect, useMemo } from "react";
import {
  AppProps,
  ErrorComponent,
  AuthenticationError,
  AuthorizationError,
  ErrorFallbackProps,
  ErrorBoundary,
  useQueryErrorResetBoundary,
  useRouter,
  useSession,
  useQuery
} from "blitz";
import { ChakraProvider, Spinner, Box } from "@chakra-ui/react";
import { ConfirmContextProvider, ConfirmProviderProps } from "chakra-confirm";
import { ReactQueryDevtools } from "react-query/devtools";
import * as Sentry from "@sentry/nextjs";
import splitbee from "@splitbee/web";

import { Language } from "db";
import getCurrentUser, { CurrentUser } from "app/users/queries/getCurrentUser";
import { LoginForm } from "app/auth/components/LoginForm";
import { theme } from "app/core/theme";
import { I18nProvider, useI18n } from "config/i18n";
import { ImpersonatingUserNotice } from "app/auth/components/ImpersonatingNotice";
import { NotNull } from "app/core/types";
import { userIsPatient } from "app/core/utils/user";
import { GlobalStyles } from "app/core/theme/GlobalStyles";

import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

if (process.env.NODE_ENV === "production") {
  splitbee.init({
    scriptUrl: "/bee.js",
    apiUrl: "/_hive"
  });
}

const getUsernameForUser = (user: NotNull<CurrentUser>) => {
  if (userIsPatient(user)) {
    return `${user.patient.clinic.slug} - ${user.patient.index}`;
  }

  return user.name || user.email;
};

const InnerApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const { setLocale, translation: t } = useI18n();
  const session = useSession();

  const [user] = useQuery(getCurrentUser, null, { suspense: false });

  useEffect(() => {
    if (user) {
      splitbee.user.set({ userId: user.id, email: user.email, username: getUsernameForUser(user) });
      Sentry.setUser({ id: user.id, email: user.email, username: getUsernameForUser(user) });
    }
  }, [user]);

  useEffect(() => {
    // TODO: Add email
    if (session.userId) {
      splitbee.user.set({ userId: session.userId.toString() });
      Sentry.setUser({ id: session.userId.toString() });
    }

    if (session.role !== "Patient") {
      setLocale(Language.en);
    }
  }, [session, setLocale]);

  const getLayout = Component.getLayout || ((page) => page);

  const defaults = useMemo(
    (): ConfirmProviderProps["defaults"] =>
      t?.confirm && {
        cancel: t.confirm.cancel,
        confirm: {
          title: t.confirm.confirm?.title,
          body: t.confirm.confirm?.body,
          buttonText: t.confirm.confirm?.buttonText
        },
        delete: {
          title: t.confirm.confirm?.title,
          body: t.confirm.confirm?.body,
          buttonText: t.confirm.confirm?.buttonText
        }
      },
    [t]
  );

  return (
    <ErrorBoundary
      FallbackComponent={RootErrorFallback}
      onReset={useQueryErrorResetBoundary().reset}
      onError={(error, componentStack) => {
        Sentry.captureException(error, { contexts: { react: { componentStack } } });
      }}
    >
      <ConfirmContextProvider defaults={defaults}>
        {getLayout(
          <>
            <GlobalStyles />
            <ImpersonatingUserNotice />
            <Component {...pageProps} />
            <ReactQueryDevtools initialIsOpen={false} />
          </>
        )}
      </ConfirmContextProvider>
    </ErrorBoundary>
  );
};

export default function App(props: AppProps) {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <I18nProvider defaultLang={Language.en}>
        <Box minH="100vh">
          <Suspense fallback={<Spinner />}>
            <InnerApp {...props} />
          </Suspense>
        </Box>
      </I18nProvider>
    </ChakraProvider>
  );
}

const RootErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
  const router = useRouter();

  useEffect(() => {
    if (error instanceof AuthorizationError) {
      if (router.asPath !== "/") {
        router.push("/").then(() => resetErrorBoundary());
      }
    }
  }, [error, resetErrorBoundary, router]);

  if (error instanceof AuthenticationError) {
    return <LoginForm onSuccess={resetErrorBoundary} />;
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    );
  } else {
    return (
      <ErrorComponent statusCode={error.statusCode || 400} title={error.message || error.name} />
    );
  }
};
