import React, { createContext, useContext, useEffect } from "react";

import { Language } from "db";
import { usePersistState } from "app/core/hooks/usePersistState";
import { I18nStore } from "./type";
import { en, hr } from "./data";

export const locals: Language[] = [Language.en, Language.hr];

export const I18nContext = createContext<I18nStore>({
  locale: Language.en,
  setLocale: () => {},
  translation: en
});

const translations = { en, hr };
export const I18nProvider: React.FC<{ defaultLang?: Language }> = ({ defaultLang, children }) => {
  const [locale, setLocale] = usePersistState<Language>("med-app:lang", defaultLang || Language.en);
  const translation = translations[locale];

  useEffect(() => {
    // Used for debugging
    // @ts-expect-error
    window.setLocale = setLocale;
  }, [setLocale]);

  return (
    <I18nContext.Provider value={{ locale, setLocale, translation }}>
      {children}
    </I18nContext.Provider>
  );
};

export const useI18n = () => {
  const value = useContext(I18nContext);
  return value;
};

export const useTranslations = () => {
  const value = useContext(I18nContext);
  return value.translation;
};
