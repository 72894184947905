import { I18n } from "../type";

export const hr: I18n = {
  home: {
    hello: "Pozdrav,",
    myVisits: "Moji posjeti",
    myResults: "Moji rezultati",
    myQuestionnaires: "Moji upitnici",
    scheduledVisits: "Nadolazeći posjeti",
    noVisits: "Nema posjeta",
    bookVisit: "Zakaži posjet",
    bookVisitConfirm: "Zakaži posjet: ",
    currentVisits: "Trenutni posjeti",
    cancelVisit: "Jeste li sigurni da želite otkazati posjet?",
    cantReschedule:
      "Ne možete premjestiti ili otkazati trenutni termin, ali možete nazvati i promijeniti",

    notifications: {
      visitDone: "Svi nalazi iz posjeta su završeni"
    },

    reschedule: {
      reschedule: "Premjesti",
      title: "Presmjeti termin",
      currentTime: "Trenutni termin",
      move: "Premjesti termin na ",
      currentVisit: "Odi na trenutni termin"
    }
  },
  notifications: {
    notifications: "Obavijesti",
    dismiss: "Pročitano",
    read: "Obavijest pročitana"
  },
  lang: {
    en: "Engleski",
    hr: "Hrvatski"
  },
  theme: {
    theme: "tema",
    light: "Svijetla",
    dark: "Tamna"
  },
  menu: {
    lang: "Jezik",
    settings: "Postavke"
  },
  settings: {
    title: "Postavke",
    email: {
      titleSet: "Postavi email addressu",
      titleChange: "Promijeni email addressu",
      fake: "Trenutno nemate postavljenu email adresu, tako da nećete dobivati obavijesti o nalazima",
      alert: "Nećete se moći ulogirati sa starom email adresom",
      buttonSet: "Postavi",
      buttonChange: "Promijeni"
    },
    password: {
      title: "Promjena lozinke",
      current: "Trenutna lozinka",
      new: "Nova lozinka",
      confirm: "Potvrda nove lozinke",
      button: "Promijeni lozinku",
      errors: {
        same: "Nova lozinka je ista kao i trenutna",
        noMatch: "Lozinke nisu iste",
        tooShort: "Lozinka je prekratka (barem 6 znakova)",
        wrongPassword: "Kriva lozinka"
      }
    }
  },
  clinic: {
    pages: {
      home: {
        title: "Klinike:"
      },
      clinic: {
        show: {
          time: "Vrijeme",
          today_visits: "Današnji posjeti",
          noPendingVisits: "Nema posjeta"
        }
      }
    }
  },
  auth: {
    logout: "Odjava",
    login: "Prijava"
  },
  errors: {
    error: "Greška",
    alreadyBooked: "Ovo vrijeme je izvan dostupnog raspona ili je već zauzeto",
    reloadError: "Pokušajte ponovno učitati stranicu"
  },
  calendar: {
    prev: "Prošli",
    today: "Danas",
    next: "Sljedeći",
    book: "Zakaži",
    notAvailable: "Nedostupno",
    overBooked: "Više prijavljenih"
  },
  reports: {
    empty: "Prazno",
    value: {
      NOT_DONE: "Nije rađeno",
      borderline: "Granično",
      positive: { long: "Pozitivno", short: "Poz" },
      negative: { long: "Negativno", short: "Neg" }
    }
  },
  core: {
    appointments: "Kalendar",
    ops: "Opcije",
    patient: "Pacijent",
    patients: "Pacijenti",
    state: "State",
    visit: "Posjet",
    visits: "Posjeti",
    questionnaires: "Upitnici",
    reports: "Nalazi",
    comment: "Komentar",
    noComment: "Nema komentara",
    therapies: "Terapije",
    cancel: "Odustani",

    time: "Vrijeme",
    date: "Datum",
    value: "Vrijednost",
    // TODO: Move to values
    myValues: "Moje vrijednosti",
    reportNotDone: "Ovaj nalaz nije gotov, ",

    days: {
      monday: "Ponedjeljak",
      tuesday: "Utorak",
      wednesday: "Srijeda",
      thursday: "Četvrtak",
      friday: "Petak",
      saturday: "Subota",
      sunday: "Nedjelja"
    },

    reportState: {
      Created: "Kreiran",
      Done: "Završen",
      InProgress: "U toku"
    },

    visitState: {
      Done: "Gotovo",
      InLab: "U laboratoriju",
      InProgress: "U toku",
      InReview: "Čeka osvrt",
      Missed: "Propušten",
      Scheduled: "Zakazano"
    }
  },
  confirm: {
    cancel: "Odustani",
    confirm: {
      title: "Jeste li sigurni?",
      buttonText: "Potvrdi"
    },
    delete: {
      title: "Jeste li sigurni?",
      body: "Jeste li sigurno da ovo želite izbrisati?",
      buttonText: "Izbriši"
    }
  },
  ops: {
    edit: "Uredi",
    open: "Otvori",
    cancel: "Otkaži",
    close: "Zatvori",
    reschedule: "Premijesi",
    hide: "Sakrij",
    show: "Pokaži"
  },
  patient: {
    refValues: "Referentni vrijednosti",
    goToVisit: "Odi na posjet",
    otherValues: "ostale vrijednosti",
    otherValuesTooltip: "Upalite ovo da biste vidjeli rijetko rađene pretrage",
    otherValuesValueTooltip: "Ova pretraga je rijetka, te je inače sakrivena"
  }
};
