import { I18n } from "../type";

export const en: I18n = {
  home: {
    hello: "Hello,",
    myVisits: "My visits",
    myResults: "My results",
    myQuestionnaires: "My questionnaires",
    scheduledVisits: "Scheduled visits",
    noVisits: "No visits",
    bookVisit: "Book visit",
    bookVisitConfirm: "Book visit on: ",
    currentVisits: "Current visits",
    cancelVisit: "Are you sure you want to cancel this visit",
    cantReschedule: "You can't cancel or reschedule this event HERE, but you can call",

    notifications: {
      visitDone: "Visit moved to done"
    },

    reschedule: {
      reschedule: "Reschedule",
      title: "Reschedule visit",
      currentTime: "Current time",
      move: "Move visit to ",
      currentVisit: "Go to current visit"
    }
  },
  notifications: {
    notifications: "Notifications",
    dismiss: "Dismiss",
    read: "Notification read"
  },
  lang: {
    en: "English",
    hr: "Croatian"
  },
  theme: {
    theme: "theme",
    light: "Light",
    dark: "Dark"
  },
  menu: {
    lang: "Language",
    settings: "Settings"
  },
  settings: {
    title: "Settings",
    email: {
      titleSet: "Set your email",
      titleChange: "Change your email",
      fake: "You currently don't have an email set therefor you won't be receiving any notifications about reports",
      alert: "You won't be able to login with your old email",
      buttonSet: "Set email",
      buttonChange: "Change email"
    },
    password: {
      title: "Change password",
      current: "Current password",
      new: "New password",
      confirm: "Confirm password",
      button: "Change password",
      errors: {
        same: "New password is same as the old password",
        noMatch: "Passwords don't match",
        tooShort: "Password is too short (at least 6 characters)",
        wrongPassword: "Wrong password"
      }
    }
  },
  clinic: {
    pages: {
      home: {
        title: "Clinics:"
      },
      clinic: {
        show: {
          time: "Time",
          today_visits: "Today's visits",
          noPendingVisits: "No pending visits"
        }
      }
    }
  },
  auth: {
    logout: "Logout",
    login: "Login"
  },
  errors: {
    error: "Error",
    alreadyBooked: "This date is out of range or already booked.",
    reloadError: "Try reloading the page"
  },
  calendar: {
    prev: "Previous",
    today: "Today",
    next: "Next",
    book: "Book",
    notAvailable: "Not available",
    overBooked: "Booked over the limit"
  },

  reports: {
    empty: "Empty",
    value: {
      NOT_DONE: "NOT_DONE",
      borderline: "Borderline",
      positive: { long: "Positive", short: "Pos" },
      negative: { long: "Negative", short: "Neg" }
    }
  },

  core: {
    appointments: "Appointments",
    ops: "Options",
    patient: "Patient",
    patients: "Patients",
    state: "State",
    visit: "Visit",
    visits: "Visits",
    questionnaires: "Questionnaires",
    reports: "Reports",
    comment: "Comment",
    noComment: "No comment",
    therapies: "Therapies",
    cancel: "Cancel",

    time: "Time",
    date: "Date",
    value: "Value",
    myValues: "My Values",
    reportNotDone: "Report not done, ",

    days: {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday"
    },

    reportState: {
      Created: "Created",
      Done: "Done",
      InProgress: "In progress"
    },

    visitState: {
      Done: "Done",
      InLab: "In lab",
      InProgress: "In progress",
      InReview: "In review",
      Missed: "Missed",
      Scheduled: "Scheduled"
    }
  },
  confirm: {
    cancel: "Cancel",
    confirm: {
      title: "Are you sure?",
      buttonText: "Confirm"
    },
    delete: {
      title: "Are you sure?",
      body: "Are you sure you want to delete this???",
      buttonText: "Delete"
    }
  },
  ops: {
    edit: "Edit",
    open: "Open",
    cancel: "Cancel",
    close: "Close",
    reschedule: "Reschedule",
    hide: "Hide",
    show: "Show"
  },
  patient: {
    refValues: "Reference Values",
    goToVisit: "Go to visit",
    otherValues: "other values",
    otherValuesTooltip: "Enable this to show non common values",
    otherValuesValueTooltip: "This value is quite rare, so it's hidden by default"
  }
};
