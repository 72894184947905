import { theme } from "@chakra-ui/react";
import { oFromEntries, oKeys } from "../utils";

export { theme };

// export const theme = extendTheme({});
export const TAG_CARD_COLOR_VARIANT = "200";
export const AVATAR_COLOR = "400";

export const POSITIVE_COLOR = "red.500";

export const ignoreColors = ["transparent", "current", "whiteAlpha", "blackAlpha"] as const;
export const colorsObj = oFromEntries(
  oKeys(theme.colors)
    .filter((a) => !ignoreColors.includes(a as any))
    .map((a) => [a, a])
);
