import { useEffect, useState } from "react";
import { safeParseJSON } from "../utils/json";

export const usePersistState = <T>(key: string, init: T) => {
  const [value, setValue] = useState<T>(
    // @ts-expect-error IDC
    safeParseJSON(typeof window !== "undefined" && window?.localStorage?.getItem?.(key), init)
  );

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const;
};
