import React, { Suspense, useState } from "react";
import { invoke, queryClient, useRouter, useSession } from "blitz";
import { Button, Flex, Spinner, Text, useColorModeValue } from "@chakra-ui/react";
import { destroyCookie } from "nookies";

import stopImpersonatingFn from "app/auth/mutations/stopImpersonating";
import { useCurrentUser } from "app/core/hooks/useCurrentUser";
import { userIsPatient } from "app/core/utils/user";

const User = () => {
  const user = useCurrentUser();
  if (!user) {
    return null;
  }

  if (userIsPatient(user)) {
    return (
      <Text
        as="span"
        fontWeight="bold"
        d="inline"
      >{`[${user.patient.clinic.name}] - ${user.patient.index}`}</Text>
    );
  }

  return <Text as="span" fontWeight="bold" d="inline">{`${user.email}`}</Text>;
};

export const ImpersonatingUserNotice = () => {
  const session = useSession();
  const router = useRouter();

  const [show, setShow] = useState(true);
  const bg = useColorModeValue("yellow.300", "yellow.700");

  if (!session.impersonatingFromUserId) return null;
  if (!show) return null;

  const stopImpersonating = async () => {
    await router.push("/");
    destroyCookie(null, "sb_uid");
    const tmp = await invoke(stopImpersonatingFn, {});

    queryClient.clear();
    window.location.href = tmp?.[1] || "/";
  };

  return (
    <Flex h="64px" w="full" bg={bg} alignItems="center" justifyContent="center">
      <Text as="div">
        Currently impersonating user{" "}
        <Suspense fallback={<Spinner />}>
          <User />
        </Suspense>
      </Text>

      <Button
        aria-label="Hide this banner"
        mx="4"
        size="sm"
        colorScheme="blue"
        onClick={() => setShow(false)}
      >
        Hide
      </Button>
      <Button
        aria-label="Stop impersonating user"
        size="sm"
        onClick={stopImpersonating}
        colorScheme="red"
      >
        Exit
      </Button>
    </Flex>
  );
};
