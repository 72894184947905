export const isValidJSON = (val: string) => {
  try {
    JSON.parse(val);
    return true;
  } catch (err) {
    return false;
  }
};

export const safeParseJSON = (val: unknown, defaultValue: {} | [] = {}) => {
  if (!val) {
    return defaultValue;
  }

  if (typeof val === "object") {
    return val;
  }

  try {
    // @ts-expect-error
    return JSON.parse(val);
  } catch (err) {
    console.error(err);
    return defaultValue;
  }
};

export const safeParseStringify = (val: any, pretty = false) => {
  return JSON.stringify(safeParseJSON(val), null, pretty ? 2 : 0);
};

export const compactJSON = (val: any) => {
  return Object.fromEntries(
    Object.entries(safeParseJSON(val)).filter(([k, v]) => k !== "" && v !== "")
  );
};
